import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { servicePage } }, location }) {

	return (
		<>
			<SEO title="Services" description={ servicePage.seoDescription } pathname={ location.pathname } />

			{ servicePage && servicePage.content &&
				<DynamicZone components={ servicePage.content } />
			}
		</>
	)
}

export const query = graphql`
	query servicesQuery {
		strapi {
			servicePage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentCommonSectionLink {
						id
						linkTitle
						linkUrl
					}
					... on StrapiQuery_ComponentCommonStatement {
						statementTitle
						statementSubTitle
					}
					... on StrapiQuery_ComponentServicesServiceList {
						show
						services {
							service {
								id
								title
								intro
								slug
								image {
									id
									url
									imageFile {
										childImageSharp {
											fluid(maxWidth: 500, maxHeight: 500) {
												...GatsbyImageSharpFluid_noBase64
											}
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentServicesStandardTextBlock {
						id
						standardTextBlockText
					}
				}
			}
		}
	}
`